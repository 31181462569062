// fonts
$thin: lighter;
$regular: normal;
$bold: bold;
$font-family: "Poppins", sans-serif;
$body-margin: 100px;
// colors
$white: #fff;
$black: #000;
$text: #2b2b2b;
$text-light: #a4a4a4;
$menu: #2b2b2b;
$light-bg: #f2f3f5;
$border-color: #e3e3e3;
$disabled-color: #c3c3c3;
$box-shadow: 0 4px 12px rgba(199, 197, 197, 0.5);
// $box-shadow-news: 0px 0px 8px rgb(0 0 0 / 6%);
$light-grey-bg: #fcfbf8;
$primary-color: #28ce84;
$secondary-color: #2b2b2b;
$tertiary-color: #f5e482;
$danger-color: #f02849;
$disabled: #f1f1f1;
$dark-grey: #757575;
$light-green: #a4c729;
$mint-green: #61b03b;
$body-bg: #f5f5f5;
