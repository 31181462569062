.top-nav {
  @include flex(flex);
  justify-content: space-between;
  background-color: $primary-color;
  box-shadow: $box-shadow;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  .left-item {
    .logo {
      width: 200px;
      background-color: darken($primary-color, 5%);
      padding: 8px 11px;
      transition: 0.3s all ease;
      display: flex;
      .ant-image-img {
        width: auto;
      }
      &.logo-toggle {
        width: 60px;
        padding: 8px 7px;
        overflow: hidden;
      }
    }
    .togglebtn {
      font-size: 26px;
      margin: 0 10px;
      color: $white;
      background: transparent;
      border: none;
    }
    .ant-input-wrapper {
      input {
        border-color: $white;
      }
    }
  }

  .user {
    @include flex(flex);
    padding: 8px 20px;
    ul {
      margin: 0;
      li {
        padding: 0 6px;
        display: inline-block;
        font-size: 18px;
        color: $white;
        a {
          @include flex(inline-flex);
          height: 40px;
          width: 40px;
          background: #f5f5f5;
          color: $text;
          border-radius: 4px;
          font-size: 24px;
        }
      }
    }
    .user-avatar {
      margin-left: 6px;
      height: 40px;
      width: 40px;
      .ant-image {
        width: 40px;
        border-radius: 4px;
        overflow: hidden;
        .ant-image-img {
          object-fit: cover;
        }
      }
      & + div {
        margin-left: 10px;
        font-size: 12px;
        h6 {
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }
}
.side-nav {
  width: 200px;
  height: calc(100vh - 56px);
  background-color: #fff;
  box-shadow: $box-shadow;
  transition: 0.3s all ease;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  ul {
    margin: 4px 0;
    li {
      padding: 4px;
      position: relative;
      .angle-down {
        position: absolute;
        right: 0px;
      }
      ul {
        padding-left: 20px;
        background-color: #fbfbfb;
        margin: 0 -10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in;
        box-shadow: inset 0 0 9px #cecece75;
        svg {
          font-size: 15px;
          width: 40px;
        }
        li {
          a {
            padding: 4px 0;
          }
        }
        &.show-sub-menu {
          max-height: 300px;
          transition: max-height 0.5s ease-in;
        }
      }
    }
    a {
      color: $text;
      display: flex;
      align-items: center;
      padding: 9px 6px;
      white-space: nowrap;
      svg {
        margin-right: 6px;
        font-size: 15px;
        min-width: 14px;
      }
      &.active {
        background-color: $primary-color;
        color: #fff;
        border-radius: 4px;
        // border-left: 2px solid $tertiary-color;
      }
    }
  }
  &.icon-only {
    width: 80px;
    a {
      span {
        display: none;
      }
      .sidebar-count {
        display: block;
        font-size: 14px;
        padding: 0px;
      }
    }
    ul li {
      svg {
        width: 40px;
      }
      .angle-down {
        right: -2px;
      }
    }
    ul.sub-menu {
      position: absolute;
      left: 80px;
      top: 0;
      background: #fff;
      box-shadow: 0 0 6px #0000001f;
      padding: 0;
      svg {
        display: block;
      }
      &.show-sub-menu {
        overflow: visible;
      }
    }
    & + .page-content {
      padding-left: 80px;
    }
  }
}
@media (max-width: 1023px) {
  .top-nav {
    display: block;
    .left-item {
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      .logo.logo-toggle {
        width: 200px !important;
        border-right: none;
      }
    }
  }
  .side-nav {
    left: -201px;
    position: absolute;
    &.icon-only {
      width: 200px;
      left: 0;
    }
  }
}

.sidebar-count {
  padding-left: 6px;
  font-size: 14px;
}
