@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap");
:root {
  --primary-color: #{$primary-color};
}
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  color: $text !important;
  font-weight: 400;
  margin: 0px;
  line-height: 1.5;
  overflow: hidden;
  background-color: $body-bg !important;
  &.overflow {
    overflow: hidden;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $light-bg;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $border-color;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $disabled-color;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $text-light;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
a {
  transition: all 600ms ease;
  color: $primary-color;
  text-decoration: none;
  &:hover,
  &:focus {
    transition: all 600ms ease;
    color: #1a7de2;
  }
}
.btn,
.ant-btn {
  min-height: 32px;
  padding: 0 15px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  &.btn-primary,
  &.ant-btn-primary {
    background-color: $primary-color;
    color: $white;
  }
  &.btn-grey {
    background-color: $border-color;
    color: $text-light;
  }
  &.ant-input-search-button {
    min-height: 32px;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 0 3px 3px 0;
    span {
      font-size: 15px;
    }
  }
  &.btn-outline {
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}

.heading {
  padding: 10px 15px;
  border-bottom: 1px solid $border-color;
}
.section-title {
  font-size: 18px;
  font-weight: 600;
}
ul {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

.ant-dropdown-trigger {
  cursor: pointer;
}

.ant-picker,
.ant-select,
.ant-input {
  width: 100%;
  // min-height: 35px;
}
.ant-select {
  height: fit-content;
}

$header-height: 56px;
$table-header-height: 62px;
$table-content-padding: 20px;

.page-wrap {
  padding-top: $header-height;
}
.page-content {
  padding: $table-content-padding;
  padding-left: 220px;
  overflow-y: auto;
  max-height: calc(100svh - $header-height);
  transition: 0.5s all ease;
  .bg-white {
    min-height: 90vh;
    padding: 15px;
    border-radius: 6px;
    box-shadow: $box-shadow;
  }
}

// antd table
@media (max-width: 1023px) {
  .ant-table-content {
    max-height: calc(
      100svh - ($header-height * 2) - ($table-content-padding * 2) -
        $table-header-height - 44px
    ) !important;
  }
}
.ant-card-body {
  padding: 0;

  .ant-table-content {
    max-height: calc(
      100svh - $header-height - ($table-content-padding * 2) -
        $table-header-height - 54px
    );
    overflow-x: auto;
    position: relative;

    .ant-table-thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .ant-pro-table-list-toolbar-container {
    padding: 15px;
    height: $table-header-height;

    .ant-space {
      width: 100%;
      justify-content: space-between;
      .ant-input-group-wrapper {
        width: 300px !important;
      }
    }
  }
  .ant-table {
    .ant-table-thead {
      th {
        background-color: #efefef !important;
        &:not(:last-child) {
          border-right: 2px solid $white;
        }
      }
    }
    th,
    td {
      padding: 8px 15px !important;
      .ant-btn-default {
        padding: 5px;
        min-height: auto !important;
        background-color: transparent;
      }
    }
  }
}
.ant-table-pagination.ant-pagination {
  margin: 15px;
}
.ant-pro-table .ant-table-tbody > tr > td {
  white-space: normal !important;
}

// modal design
.ant-modal-wrap {
  @include flex(flex);
}
.ant-modal {
  padding: 0;
  top: 0;
  max-width: 900px;
  width: 100% !important;
  border-radius: 6px;
  .ant-modal-body {
    max-height: 72vh;
    overflow: auto;
  }
}

// dropdown
.ant-dropdown {
  min-width: 150px !important;
  top: 62px !important;
  svg {
    @include position(-17px, 5px, initial);
    font-size: 27px;
    color: $white;
  }
}

// spin
.ant-spin-spinning {
  top: 50%;
  left: 50%;
  position: absolute;
}

// mobile design
@media (max-width: 1023px) {
  .page-wrap {
    padding-top: 106px;
  }
  .page-content {
    padding-left: 20px !important;
    max-height: calc(100svh - 90px);
  }
  .ant-pro-table-list-toolbar-container {
    & > .ant-space {
      display: block;
    }
    .ant-pro-table-list-toolbar-setting-items {
      justify-content: flex-end !important;
      margin-top: 15px;
    }
  }
  .ant-col {
    width: 100%;
  }
}

.ck.ck-editor {
  img {
    height: 400px;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: baseline;
}
.ant-image-mask-info {
  font-size: 12px;
}
.ant-collapse-header {
  font-size: 1.2rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 12px !important;
  }
}
.worker-table-collapse {
  .ant-collapse-header {
    font-weight: 700;
  }
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

// Checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

// Tabs
.ant-tabs-tab:hover {
  color: $primary-color;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-btn:active {
  color: $primary-color;
}
.ant-tabs-ink-bar {
  background-color: $primary-color;
}

.order-modal-tab {
  .ant-tabs-content-holder {
    overflow-y: auto;
    padding-inline: 8px;
  }
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c3c3c3;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  text-align: center;
  cursor: pointer;

  &[aria-disabled="true"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.responsive-two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.uploaded-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px dashed #c3c3c3;
}

.spinner {
  display: flex;
  position: static;
  font-size: inherit;
  color: inherit;
  margin-top: 3px;

  * {
    font-size: inherit !important;
  }
}

.multi-status-select {
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 6px;
    }
  }
}
.multi-status-dropdown {
  @media screen and (max-width: 768px) {
    left: 0 !important;
    right: 0 !important;
    margin-inline: auto;
  }
}

.installation-bg-green {
  background-color: rgb(171, 255, 171);
}
.installation-bg-red {
  background-color: rgb(255, 105, 105);
}
.installation-bg-gray {
  background-color: #c3c3c3;
}
.installation-bg-yellow {
  background-color: rgb(255, 202, 104);
}

.ant-table-tbody {
  tr.ant-table-row:hover {
    td {
      background: initial;
    }
  }
}

.worker-order-select {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.installation-modal-header {
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;

  :first-child {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .last-child {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.flex-1 {
  flex: 1;
}
.underline {
  text-decoration: underline;
}
.font-normal {
  font-weight: normal;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.m-0 {
  margin: 0;
}
.mt-4 {
  margin-top: 1rem;
}
.mb-4 {
  margin-top: 1rem;
}
.p-2 {
  padding: 0.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.break-line {
  white-space: pre-line;
}
.grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-gap-4 {
  column-gap: 1rem;
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
.bg-red {
  background-color: red;
}
.bg-primary {
  background-color: $primary-color !important;
}
.bg-disabled {
  background-color: $disabled-color !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.modal-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  min-height: auto;
}

.price-table {
  border: 1px solid;
  width: 100%;

  th,
  td {
    border: 1px solid;
    padding: 0.5rem;
  }
}

.updated-price {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.5rem;
  margin-top: 1rem;
  font-size: 1.4rem;
}

.remove-style {
  all: unset;

  > button {
    all: unset;
    background: none !important;
  }
}

.remove-style:hover {
  all: unset;
}

.small-label-form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    height: auto;
    font-size: 12px;
  }
}

.order-table {
  .row-active {
    .ant-table-cell {
      border-bottom: 2px solid red;
      border-top: 2px solid red;
      transition: background 0.3s;

      &:first-child {
        border-left: 2px solid red;
      }

      &:last-child {
        border-right: 2px solid red;
      }
    }
  }
}

// TinyMCE
.tox-tinymce {
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}

// Header Add Link in Order Table
.header-add-link {
  .icon {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .icon {
      display: block;
    }
    .text {
      display: none;
    }
  }
}

.file-preview-overlay {
  position: absolute;
  inset: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.65);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: default;
}

.ant-tag-blue {
  background-color: $primary-color !important;
  color: white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primary-color !important;
  color: white !important;
}

.recharts-custom-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
