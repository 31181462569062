@mixin flex($displayFlex) {
  display: $displayFlex;
  justify-content: center;
  align-items: center;
}
@mixin position($top, $right, $left) {
  position: absolute;
  top: $top;
  right: $right;
  left: $left;
  content: '';
}
