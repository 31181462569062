.sort-header {
  padding: 15px;
  border-bottom: 1px solid $border-color;
  justify-content: space-between;
  display: flex;
}
.ant-tree-node-content-wrapper {
  line-height: 40px !important;
}
.padding-15 {
  padding: 15px;
}
