.login-wrap {
	min-height: 100vh;
	.login-bg {
		background-color: $primary-color;
		padding: 15px;
		.logo {
			margin-bottom: 30px;

			img {
				max-width: 500px;
			}
		}
	}
	.login-form {
		@include flex(flex);
		background-color: $white;
	}
	form {
		width: 400px;
		margin: 0 auto;
		padding: 20px;
		label {
			font-weight: 600;
			text-transform: capitalize;
		}
		.form-control {
			border-radius: 0;
			border: none;
			border-bottom: 1px solid $border-color;
		}
		button {
			width: 100%;
			min-height: 45px;
			border-radius: 30px;
		}
		.ant-input-password {
			display: flex;
		}
	}
}

@media (min-width: 992px) {
	.login-bg {
		min-height: 100vh;
	}
}
@media (max-width: 991px) {
	.login-wrap {
		display: flex;
		flex-direction: column;
	}
}

.verify-wrap {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 400px;
	margin: 0 auto;
	text-align: center;
	svg {
		font-size: 90px;
		margin-bottom: 10px;
		color: #f44336;
	}
}
@media (min-width: 992px) {
	.login-bg {
		min-height: 100vh;
	}
}
@media (max-width: 991px) {
	.login-wrap {
		display: flex;
		flex-direction: column;
	}
}
